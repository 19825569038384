import React, { FocusEvent, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import Image from 'next/legacy/image';
import Trans from 'next-translate/Trans';
import cx from 'classnames';
import * as ga from '../../../lib/ga';

import {
  checkIsValidPostalCode,
  downloadAppImg,
  isUSAPostalCode,
  partnerLogoImg,
  sectionImg,
  shoppingListSvg,
} from '../../helpers/homepage';
import { icons } from '../../../images/Icons';
import { SearchInput } from '../../widgets';
import styles from './home.module.scss';
import MobileBanner from '../mobileBanner';
import useSessionStorage from '../../hooks/useSessionStorage';
import useUserAgent from '../../hooks/useUserAgent';
import useProgressiveImage from '../../hooks/useProgessiveImage';
import useBlackFriday from '../../hooks/useBlackFriday';
import { CityLocation, Country, getPostalCode, getPostalCodeNG } from '../../helpers/location';
import useTranslation from 'next-translate/useTranslation';
import { getAmpUrl } from '../../helpers/links';
import AppConstants from '../../AppConstants';
import { fromJsDate } from '../../helpers/date';
import Link from 'next/link';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

const LOCATION_INFO = 'location_info';

interface HomeProps {
  cities: CityLocation[];
}
export default function Home({ cities }: HomeProps): JSX.Element {
  const flags = useFlags();
  const ldClient = useLDClient();
  const router = useRouter();
  const { t, lang } = useTranslation('home');
  const { getItem } = useSessionStorage();
  const { isGooglebot } = useUserAgent();
  const { imageCssClass } = useProgressiveImage();
  const Component = (props) => <p {...props} />;

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [searchByPostalCodeUrl, setSearchByPostalCodeUrl] = useState<string>();
  const [searchByProductsUrl, setSearchByProductsUrl] = useState<string>();
  const [searchFlyersUrl, setSearchFlyersUrl] = useState<string>();
  const [searchCouponsUrl, setSearchCouponsUrl] = useState<string>();
  const [shoppingListUrl, setShoppingListUrl] = useState<string>();
  const [isCyberWeekPeriod, setIsCyberWeekPeriod] = useState<boolean>();

  const toggleMenu = useCallback(() => setIsMenuOpen(!isMenuOpen), [isMenuOpen]);

  const [isValidZipCode, setIsValidZipCode] = useState<boolean>(true);

  const [zipCode, setZipCode] = useState<string>('');
  const [country, setCountry] = useState<Country>(null);
  const { isBlackFridayEnabled, blackFridayLink } = useBlackFriday({ lang, zipCode });

  const handleSearchZipCode = useCallback(
    (e: string): void => {
      const value = e.trim().toUpperCase();
      setZipCode(value);
      setIsValidZipCode(checkIsValidPostalCode(value));
    },
    [setIsValidZipCode, setZipCode],
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>): void => {
      if (!e.target.value && JSON.parse(localStorage.getItem(LOCATION_INFO))) {
        setIsValidZipCode(true);
        const info = JSON.parse(localStorage.getItem(LOCATION_INFO));
        setZipCode(info.postal_code);
      }
    },
    [setIsValidZipCode, setZipCode],
  );

  const [products, setProducts] = useState<string>('');

  useEffect(() => {
    if (isValidZipCode) {
      const flyersName = isUSAPostalCode(zipCode)
        ? 'weekly_ads'
        : router?.locale === 'fr-ca'
        ? 'circulaires'
        : 'flyers';
      const locale = isUSAPostalCode(zipCode) ? 'en-us' : router?.locale === 'fr-ca' ? 'fr-ca' : 'en-ca';
      const postalCodeUrl = `${process.env.FLIPP_WEB_HOST}/${locale}/${flyersName}?postal_code=${zipCode}`;
      setSearchByPostalCodeUrl(addSyndParam(postalCodeUrl));

      const productsUrl = `${process.env.FLIPP_WEB_HOST}/search/${products}`;
      setSearchByProductsUrl(addSyndParam(productsUrl));

      const flyersUrl = `${process.env.FLIPP_WEB_HOST}/flyers`;
      setSearchFlyersUrl(addSyndParam(flyersUrl));

      const couponsUrl = `${process.env.FLIPP_WEB_HOST}/coupons`;
      setSearchCouponsUrl(addSyndParam(couponsUrl));

      const listUrl = `${process.env.FLIPP_WEB_HOST}/shopping_list`;
      setShoppingListUrl(addSyndParam(listUrl));
    }
  }, [zipCode, isValidZipCode, products, router]);

  const updatePostalCode = useCallback((): void => {
    if (isValidZipCode) {
      const updatedInfo = { postal_code: zipCode };
      localStorage.setItem(LOCATION_INFO, JSON.stringify(updatedInfo));
    }
  }, [isValidZipCode, zipCode]);

  const handleNavigate = useCallback(
    (e: React.KeyboardEvent | React.MouseEvent, url: string): void => {
      if (isValidZipCode) {
        e.preventDefault();
        updatePostalCode();
        // router.push(url); // TODO: use it when flyers browsing page is migrated to nextjs
        window.location.href = url;
      }
    },
    [isValidZipCode, zipCode],
  );

  const handleSearchFlipp = useCallback(
    (e: React.MouseEvent): void => {
      e.preventDefault();
      if (products) {
        router.push(`${process.env.FLIPP_WEB_HOST}/search/${products}`);
      }
    },
    [products],
  );

  useEffect(() => {
    ga.pageview({
      page_title: window.location.pathname + window.location.search,
      page_path: '/home',
    });
  }, []);

  useEffect(() => {
    if (router) {
      localStorage.setItem('locale', router.locale);
    }
  }, []);

  useEffect(() => {
    const nowDay = fromJsDate(new Date());
    const dateStart = fromJsDate(new Date('November 25, 2023 00:00:01'));
    setIsCyberWeekPeriod(nowDay >= dateStart);
  }, []);

  useEffect(() => {
    const locationInfo = JSON.parse(localStorage.getItem(LOCATION_INFO));
    if (!locationInfo) {
      if (ldClient) {
        const fetchData = async () => {
          const getPostalCodeUrl = flags.releaseDeprecateBackflippLocationByIp ? getPostalCodeNG : getPostalCode;
          const res = await fetch(getPostalCodeUrl);
          const data = await res.json();
          const postalCode = data.postal_code;
          setZipCode(postalCode);
          setCountry(data.country_code_by_ip === 'CA' ? Country.CA : Country.US);
          localStorage.setItem(LOCATION_INFO, JSON.stringify(data));
          setIsValidZipCode(checkIsValidPostalCode(postalCode));
        };
        fetchData();
      }
    } else {
      setZipCode(locationInfo.postal_code);
      setCountry(
        locationInfo.country_code_by_ip === 'CA' || locationInfo.country_code === 'CA' ? Country.CA : Country.US,
      );
    }
  }, [ldClient]);

  const addSyndParam = (url: string): string => {
    const synd = getItem('synd');
    const params = new URLSearchParams(window.location.search);
    const usynd = params.get('synd');
    if (synd || usynd) {
      const urlObj = new URL(url);
      urlObj.searchParams.set('synd', synd || usynd);
      return urlObj.href;
    }
    return url;
  };

  const loadFromPublicFolder = ({ src, width }) => {
    return `${src}?w=${width}`;
  };

  function imgLoader({ src }) {
    return src;
  }

  function renderCountriesList() {
    const path = window.location.pathname;
    if (path === '/home' || path === '/') {
      return (
        <ul className={styles.footerList}>
          <li className={styles.footerItem}>
            <a className={styles.footerItemLink} href={'/en-ca'}>
              Canada
            </a>
          </li>
          <li>
            <a className={styles.footerItemLink} href={'/en-us'}>
              USA
            </a>
          </li>
        </ul>
      );
    }

    return null;
  }

  function renderCitiesList() {
    const toLinks = (cityObj: CityLocation) => {
      const { name: city, state } = cityObj;
      const href = `${process.env.FLIPP_WEB_HOST}/${router.locale}/${city.replace(' ', '-')}-${state}`.toLowerCase();
      return (
        <li className={styles.footerItem} key={city}>
          <a className={styles.footerItemLink} href={href}>
            {city}
          </a>
        </li>
      );
    };
    return (
      <ul className={styles.footerList}>
        {cities.map(toLinks)}
        <li className={styles.footerItem}>
          <a className={styles.footerItemLink} href={`/${router.locale}/browser`}>
            Location Browser
          </a>
        </li>
        <li className={styles.footerItem}>
          <a className={styles.footerItemLink} href={`/${router.locale}/categories`}>
            Categories Browser
          </a>
        </li>
      </ul>
    );
  }

  function renderChangeLanguageButton() {
    if (country === null || country === 'USA' || router?.locale === 'en-us') {
      return null;
    }
    const locale = router?.locale === 'en-ca' ? 'fr-ca' : 'en-ca';
    const text = router?.locale === 'en-ca' ? 'Français' : 'English';
    return (
      <Link href="/" locale={locale} prefetch={true} passHref className={styles.link}>
        {text}
      </Link>
    );
  }

  return (
    <>
      <Head>
        <title>{t('home_page_title')}</title>
        <link rel="amphtml" href={getAmpUrl(router)} />
        <meta
          content="width=device-width, height=device-height, initial-scale=1.0, viewport-fit=cover"
          name="viewport"
        />
      </Head>
      <MobileBanner />
      <header id="header" className={cx('container', styles.header)}>
        <nav className={styles.headerNav}>
          <a className={styles.headerLogo} href="https://corp.flipp.com" target="_blank" rel="noreferrer">
            {icons.flippLogo}
          </a>
          <a href="#weekly_ads" className={styles.link}>
            {t('flyers_header_category')}
          </a>
          <a href="#coupons" className={styles.link}>
            {t('coupons_title')}
          </a>
          <a href="#shopping_list" className={styles.link}>
            {t('shopping_list')}
          </a>
          <a href="#search" className={styles.link}>
            {t('search')}
          </a>
        </nav>
        <nav className={cx(styles.headerNav, isMenuOpen && styles.mobileMenu)}>
          {renderChangeLanguageButton()}
          <a target="_blank" href={t('about_us_link')} className={styles.link} rel="noreferrer">
            {t('about_flipp')}
          </a>
          <a target="_blank" href="https://app.flipp.com" className={styles.link} rel="noreferrer">
            {t('flipp_mobile')}
          </a>
          <a href="https://blog.flipp.com" className={styles.link}>
            {t('blog_button')}
          </a>
        </nav>
        <nav className={styles.mobile}>
          <a className={styles.headerLogo} href="https://corp.flipp.com" target="_blank" rel="noreferrer">
            {icons.flippLogo}
          </a>
          <button onClick={toggleMenu} className={styles.navHamburger} aria-label="Menu">
            <div>{isMenuOpen ? icons.x : icons.hamburger}</div>
          </button>
        </nav>
      </header>
      {isBlackFridayEnabled ? (
        <section className={styles.bfHero}>
          <div className={styles.bfPostal}>
            <div className={styles.bfTitle}>
              {isCyberWeekPeriod ? (
                <div className={styles.cwTitle}>{t('cw_title')}</div>
              ) : (
                <Trans
                  i18nKey="home:bf_title"
                  components={[<Component key="bf_title" />, <span style={{ display: 'block' }} key="bf_title" />]}
                />
              )}
            </div>
            <div className={styles.bfWrap}>
              <SearchInput
                value={zipCode}
                placeholder={t('postalCode')}
                onChange={handleSearchZipCode}
                data-cy="postalCodeInput"
                onBlur={handleBlur}
                onKeyDown={(e) => e.code === 'Enter' && handleNavigate(e, searchByPostalCodeUrl)}
                maxLength={7}
                wrapperClassName={cx(styles.bfInput, isValidZipCode || styles.notValid)}
              >
                {''}
              </SearchInput>
              {isValidZipCode || <p className={styles.notValidInfo}>{t('enter_postal_code_error_message')}</p>}
              <a
                href={searchByPostalCodeUrl}
                onClick={updatePostalCode}
                className={cx(isValidZipCode || styles.disabled)}
              >
                {t('start_saving')}
              </a>
            </div>
          </div>
        </section>
      ) : null}
      <main className={cx('container', styles.homepage)}>
        {!isBlackFridayEnabled ? (
          <section>
            <div className={cx(styles.zipContainer, 'flex')}>
              <h1 className={styles.zipTitle}>{t('index_page_h1')}</h1>
              <div className={styles.zipSearchBar}>
                <Trans
                  i18nKey="home:how_to_save_feature1"
                  components={[<Component key="how_to_save_feature1" />, <strong key="how_to_save_feature1" />]}
                />
                <SearchInput
                  maxLength={7}
                  data-cy="postalCodeInput"
                  placeholder={t('postalCode')}
                  value={zipCode}
                  onChange={handleSearchZipCode}
                  onBlur={handleBlur}
                  onKeyDown={(e) => e.code === 'Enter' && handleNavigate(e, searchByPostalCodeUrl)}
                  wrapperClassName={cx(styles.searchInput, isValidZipCode || styles.notValid)}
                >
                  <a
                    className={cx(styles.searchButton, isValidZipCode || styles.disabled)}
                    href={searchByPostalCodeUrl}
                    data-cy="startSaving"
                    onClick={updatePostalCode}
                  >
                    {t('start_saving')}
                  </a>
                </SearchInput>
                {isValidZipCode || <p className={styles.notValidInfo}>{t('enter_postal_code_error_message')}</p>}
              </div>
            </div>
            <section className={cx(styles.heroImage, imageCssClass)} />
          </section>
        ) : null}
        <section id="weekly_ads" className={cx('flex', styles.storesWrapper)}>
          <div className={styles.stores}>
            <Trans
              i18nKey="home:flyers_feature_heading"
              components={[<Component key="flyers_feature_heading" />, <strong key="flyers_feature_heading" />]}
            />
            <p className={styles.storesParagraph}>{t('flyers_feature_description1')}</p>
            <Trans
              i18nKey="home:flyers_feature_description2"
              components={[
                <Component key="flyers_feature_description2" />,
                <a className={styles.storesLink} href="https://app.flipp.com" key="flyers_feature_description2" />,
              ]}
            />
            <div className={cx('flex', styles.storesImgWrapper)}>
              <div className={styles.storesLogo}>
                <Image
                  src="/landing/logo-walmart.png"
                  alt="Walmart"
                  width={partnerLogoImg.walmart.width}
                  height={partnerLogoImg.walmart.height}
                  loader={loadFromPublicFolder}
                />
              </div>
              <div className={styles.storesLogo}>
                <Image
                  src="https://images.wishabi.net/merchants/TwHOf9cj5fUCEQ==/RackMultipart20210421-1-tp46bo.jpeg"
                  alt="Kroger"
                  width={partnerLogoImg.kroger.width}
                  height={partnerLogoImg.kroger.height}
                  loader={imgLoader}
                />
              </div>
              <div className={styles.storesLogo}>
                <Image
                  src="/landing/logo-homedepot.png"
                  alt="Homedepot"
                  width={partnerLogoImg.homedepot.width}
                  height={partnerLogoImg.homedepot.height}
                  loader={loadFromPublicFolder}
                />
              </div>
              <div className={styles.storesLogo}>
                <Image
                  src="/landing/logo-cvs.png"
                  alt="CVS"
                  width={partnerLogoImg.cvs.width}
                  height={partnerLogoImg.cvs.height}
                  loader={loadFromPublicFolder}
                />
              </div>
              <div className={styles.storesLogo}>
                <Image
                  src="/landing/logo-toysrus.png"
                  alt="toysrus"
                  width={partnerLogoImg.toysrus.width}
                  height={partnerLogoImg.toysrus.height}
                  loader={loadFromPublicFolder}
                />
              </div>
              <div className={styles.storesLogo}>
                <Image
                  src="/landing/logo-meijer.png"
                  alt="meijer"
                  width={partnerLogoImg.meijer.width}
                  height={partnerLogoImg.meijer.height}
                  loader={loadFromPublicFolder}
                />
              </div>
              <div className={styles.storesLogo}>
                <Image
                  src="/landing/logo-familydollar.png"
                  alt="familydollar"
                  width={partnerLogoImg.familydollar.width}
                  height={partnerLogoImg.familydollar.height}
                  loader={loadFromPublicFolder}
                />
              </div>
              <div className={styles.storesLogo}>
                <Image
                  src="/landing/logo-dicks.png"
                  alt="dicks"
                  width={partnerLogoImg.dicks.width}
                  height={partnerLogoImg.dicks.height}
                  loader={loadFromPublicFolder}
                />
              </div>
            </div>
            <a className={styles.button} href={searchFlyersUrl}>
              {t('flyers_feature_cta')}
            </a>
          </div>
          <Image
            className={styles.storesImg}
            src="/landing/flyers-us.jpeg"
            alt="flyers"
            width={sectionImg.width}
            height={sectionImg.height}
            loader={loadFromPublicFolder}
          />
        </section>
        <section id="coupons" className={cx('flex', styles.storesWrapper, styles.coupons)}>
          <Image
            src="/landing/coupons-us.jpg"
            alt="coupons"
            width={sectionImg.width}
            height={sectionImg.height}
            loader={loadFromPublicFolder}
          />
          <div className={cx(styles.stores, styles.couponsInner)}>
            <Trans
              i18nKey="home:coupons_feature_heading"
              components={[<Component key="coupons_feature_heading" />, <strong key="coupons_feature_heading" />]}
            />
            <p className={styles.storesParagraph}>{t('coupons_feature_description1')}</p>
            <Trans
              i18nKey="home:coupons_feature_description2"
              components={[
                <Component key="coupons_feature_description2" />,
                <a href="https://app.flipp.com" className={styles.storesLink} key="coupons_feature_description2" />,
              ]}
            />
            <div className={cx('flex', styles.storesImgWrapper, styles.couponsLogos)}>
              <div className={styles.storesLogo}>
                <Image
                  className={styles.partnerLogo}
                  src="/landing/CVS.png"
                  alt="CVS"
                  width={partnerLogoImg.CVS.width}
                  height={partnerLogoImg.CVS.height}
                  loader={loadFromPublicFolder}
                />
              </div>
              <div className={styles.storesLogo}>
                <Image
                  className={styles.partnerLogo}
                  src="/landing/FamilyDollar.png"
                  alt="FamilyDollar"
                  width={partnerLogoImg.FamilyDollar.width}
                  height={partnerLogoImg.FamilyDollar.height}
                  loader={loadFromPublicFolder}
                />
              </div>
              <div className={styles.storesLogo}>
                <Image
                  className={styles.partnerLogo}
                  src="/landing/Meijer.png"
                  alt="Meijer"
                  width={partnerLogoImg.Meijer.width}
                  height={partnerLogoImg.Meijer.height}
                  loader={loadFromPublicFolder}
                />
              </div>
              <div className={styles.storesLogo}>
                <Image
                  className={styles.partnerLogo}
                  src="/landing/Publix.png"
                  alt="Publix"
                  width={partnerLogoImg.Publix.width}
                  height={partnerLogoImg.Publix.height}
                  loader={loadFromPublicFolder}
                />
              </div>
              <div className={styles.storesLogo}>
                <Image
                  className={styles.partnerLogo}
                  src="/landing/RiteAid.png"
                  alt="RiteAid"
                  width={partnerLogoImg.RiteAid.width}
                  height={partnerLogoImg.RiteAid.height}
                  loader={loadFromPublicFolder}
                />
              </div>
              <div className={styles.storesLogo}>
                <Image
                  className={styles.partnerLogo}
                  src="/landing/Walgreens.png"
                  alt="Walgreens"
                  width={partnerLogoImg.Walgreens.width}
                  height={partnerLogoImg.Walgreens.height}
                  loader={loadFromPublicFolder}
                />
              </div>
            </div>
            <a href={searchCouponsUrl} className={styles.button}>
              {t('coupons_feature_cta')}
            </a>
          </div>
        </section>
        <section id="shopping_list" className={cx('flex', styles.storesWrapper)}>
          <div className={styles.stores}>
            <Trans
              i18nKey="home:sl_feature_heading"
              components={[<Component key="sl_feature_heading" />, <strong key="sl_feature_heading" />]}
            />
            <Trans
              i18nKey="home:sl_feature_description"
              components={[
                <Component key="sl_feature_description1" />,
                <a href="https://app.flipp.com" className={styles.storesLink} key="sl_feature_description1" />,
              ]}
            />
            <div className={cx('flex', styles.storesImgWrapper)}>
              <div className={styles.storesLogo}>
                <div className={styles.couponLogoInner}>
                  <Image
                    src="/landing/myMilk.svg"
                    alt="Milk"
                    width={shoppingListSvg.width}
                    height={shoppingListSvg.height}
                    loader={loadFromPublicFolder}
                  />
                </div>
              </div>
              <div className={styles.storesLogo}>
                <div className={styles.couponLogoInner}>
                  <Image
                    src="/landing/myEggs.svg"
                    alt="Eggs"
                    width={shoppingListSvg.width}
                    height={shoppingListSvg.height}
                    loader={loadFromPublicFolder}
                  />
                </div>
              </div>
              <div className={styles.storesLogo}>
                <div className={styles.couponLogoInner}>
                  <Image
                    src="/landing/myButter.svg"
                    alt="Butter"
                    width={shoppingListSvg.width}
                    height={shoppingListSvg.height}
                    loader={loadFromPublicFolder}
                  />
                </div>
              </div>
              <div className={styles.storesLogo}>
                <div className={styles.couponLogoInner}>
                  <Image
                    src="/landing/myCheese.svg"
                    alt="Cheese"
                    width={shoppingListSvg.width}
                    height={shoppingListSvg.height}
                    loader={loadFromPublicFolder}
                  />
                </div>
              </div>
              <div className={styles.storesLogo}>
                <div className={styles.couponLogoInner}>
                  <Image
                    src="/landing/myBread.svg"
                    alt="Bread"
                    width={shoppingListSvg.width}
                    height={shoppingListSvg.height}
                    loader={loadFromPublicFolder}
                  />
                </div>
              </div>
              <div className={styles.storesLogo}>
                <div className={styles.couponLogoInner}>
                  <Image
                    src="/landing/myApple.svg"
                    alt="Apple"
                    width={shoppingListSvg.width}
                    height={shoppingListSvg.height}
                    loader={loadFromPublicFolder}
                  />
                </div>
              </div>
              <div className={styles.storesLogo}>
                <div className={styles.couponLogoInner}>
                  <Image
                    src="/landing/myBroccoli.svg"
                    alt="Broccoli"
                    width={shoppingListSvg.width}
                    height={shoppingListSvg.height}
                    loader={loadFromPublicFolder}
                  />
                </div>
              </div>
              <div className={styles.storesLogo}>
                <div className={styles.couponLogoInner}>
                  <Image
                    src="/landing/myYogurt.svg"
                    alt="Yogurt"
                    width={shoppingListSvg.width}
                    height={shoppingListSvg.height}
                    loader={loadFromPublicFolder}
                  />
                </div>
              </div>
            </div>
            <a href={shoppingListUrl} className={styles.button}>
              {t('sl_feature_cta')}
            </a>
          </div>
          <Image
            className={styles.partnerLogo}
            src="/landing/flyers-us.jpeg"
            alt="flyers"
            width={sectionImg.width}
            height={sectionImg.height}
            loader={loadFromPublicFolder}
          />
        </section>
        <div id="search" className={styles.searchItems}>
          <Trans
            i18nKey="home:search_value"
            components={[<Component key="search_value" />, <strong key="search_value" />]}
          />
          <SearchInput
            maxLength={20}
            placeholder={t('deal_search_desktop')}
            onChange={setProducts}
            value={products}
            onKeyDown={(e) => e.code === 'Enter' && handleNavigate(e, searchByProductsUrl)}
            wrapperClassName={styles.searchProductInput}
          >
            <a className={styles.searchButton} onClick={handleSearchFlipp}>
              {t('search_flipp')}
            </a>
          </SearchInput>
        </div>
      </main>
      <aside className={styles.downloadApp}>
        <div className={styles.downloadAppInfo}>
          <Trans
            i18nKey="home:mobile_title_new"
            components={[<Component key="mobile_title_new" />, <strong key="mobile_title_new" />]}
          />
          <p className={styles.downloadAppParagraph}>{t('mobile_value')}</p>
          <div className={styles.buttons}>
            <a
              href={AppConstants.MOBILE_APP_LINK_BUTTON}
              className={styles.buttonIOS}
              title="Download on the App Store"
              target="_blank"
              rel="noreferrer"
            />

            <a
              href={AppConstants.MOBILE_APP_LINK_BUTTON}
              className={styles.buttonAndroid}
              title="Get it on Google Play"
              target="_blank"
              rel="noreferrer"
            />
          </div>
        </div>
        <div className={styles.downloadAppImg}>
          <Image
            width={downloadAppImg.width}
            height={downloadAppImg.height}
            alt="Image of the Flipp app shown on the iPhone"
            src="/landing/app-us.png"
            loader={loadFromPublicFolder}
          />
        </div>
        <a href="#header" className={styles.scrollUp} title="return to top of page">
          {icons.backArrow}
        </a>
      </aside>
      <footer>
        {isGooglebot && renderCountriesList()}
        <div className={styles.footer}>
          <div className={styles.footerListWrapper}>
            <a className={styles.headerLogo} href="https://corp.flipp.com" target="_blank" rel="noreferrer">
              {icons.flippLogo}
            </a>
            {isGooglebot && renderCitiesList()}
            <ul className={styles.footerList}>
              <li className={styles.footerItem}>
                <a
                  className={styles.footerItemLink}
                  href="https://corp.flipp.com/platforms/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('retail_platform')}
                </a>
              </li>
              <li className={styles.footerItem}>
                <a
                  className={styles.footerItemLink}
                  href="https://corp.flipp.com/platforms/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('media_platform')}
                </a>
              </li>
              <li className={styles.footerItem}>
                <a
                  className={styles.footerItemLink}
                  href={AppConstants.MOBILE_APP_LINK_FOOTER}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('mobile_app')}
                </a>
              </li>
              <li className={styles.footerItem}>
                <a className={styles.footerItemLink} href={t('about_us_link')} target="_blank" rel="noreferrer">
                  {t('about_us')}
                </a>
              </li>
              <li className={styles.footerItem}>
                <a className={styles.footerItemLink} href={t('contact_us_link')} target="_blank" rel="noreferrer">
                  {t('contact_us')}
                </a>
              </li>
              <li className={styles.footerItem}>
                <a className={styles.footerItemLink} href={t('help_link')} target="_blank" rel="noreferrer">
                  {t('help')}
                </a>
              </li>
            </ul>
            <ul className={styles.footerList}>
              <li className={styles.footerItem}>
                <a
                  className={styles.footerItemLink}
                  href="https://corp.flipp.com/careers-culture"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('careers')}
                </a>
              </li>
              <li className={styles.footerItem}>
                <a
                  className={styles.footerItemLink}
                  href="https://merchants.wishabi.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('client_login')}
                </a>
              </li>
              <li className={styles.footerItem}>
                <a
                  className={styles.footerItemLink}
                  href="https://corp.flipp.com/legal/privacy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('email_signup_privacy')}
                </a>
              </li>
              <li className={styles.footerItem}>
                <a
                  className={styles.footerItemLink}
                  href="https://corp.flipp.com/legal/terms_of_use/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('terms_of_use')}
                </a>
              </li>
              <li className={styles.footerItem}>
                <a
                  className={styles.footerItemLink}
                  href="https://corp.flipp.com/legal/accessibility_plan/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('accessibility')}
                </a>
              </li>
              <li className={styles.footerItem}>
                <a
                  className={styles.footerItemLink}
                  href="https://corp.flipp.com/legal/privacy/#AdChoices"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('ad_choices')}
                </a>
              </li>
              {isBlackFridayEnabled ? (
                <li className={styles.footerItem}>
                  <a className={styles.footerItemLink} href={`${process.env.FLIPP_WEB_HOST}/${blackFridayLink}`}>
                    {t('blackfriday.deals')}
                  </a>
                </li>
              ) : null}
            </ul>
          </div>
          <div className={styles.footerBlog}>
            <p className={styles.footerBlogTitle}>{t('blog_desc')}</p>
            <a
              className={styles.footerButton}
              target="_blank"
              href="https://blog.flipp.com/?utm_source=Flipp%20Web&amp;utm_medium=Organic&amp;utm_campaign=Blog%20Home"
              rel="noreferrer"
            >
              {t('read_blog')}
            </a>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.copyright}>
          <div className={styles.copyrightContainer}>
            <a
              href="https://www.facebook.com/getflipp"
              className={styles.fb}
              title="link opens in new tab, graphic, Facebook"
              target="_blank"
              rel="noreferrer"
            />
            <a
              href="https://www.instagram.com/getflipp"
              className={styles.insta}
              title="link opens in new tab, graphic, Instagram"
              target="_blank"
              rel="noreferrer"
            />
            <a
              href="https://www.linkedin.com/company/flipp/"
              className={styles.linkedin}
              title="link opens in new tab, graphic, Linkedin"
              target="_blank"
              rel="noreferrer"
            />
          </div>
          <Trans
            i18nKey="home:copyright"
            components={[<Component key="copyright" />, <span key="copyright">{new Date().getUTCFullYear()}</span>]}
          />
        </div>
      </footer>
    </>
  );
}
